export default {
  firebase: {
    apiKey: 'AIzaSyC7fddeOJt58AA_FVbMvuarbHFB5LhDaL4',
    authDomain: 'mirage-api-stag.firebaseapp.com',
    databaseURL: 'https://mirage-api-stag.firebaseio.com',
    projectId: 'mirage-api-stag',
    storageBucket: 'mirage-api-stag.appspot.com',
    messagingSenderId: '',
  },
  apiUrl: 'https://stag.api.mirageid.com/api',
  baseUrl: 'https://stag.api.mirageid.com',
  chargebeeUrl: 'https://mirageid-test.chargebee.com/d/invoices/',
};
